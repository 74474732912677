import React from 'react'

import siteConfig from '../../utils/siteConfig'

const SocialIcons = ({ title, url }) => {
  const link = `${siteConfig.siteUrl}${url}`

  const facebook = `https://www.facebook.com/sharer/sharer.php?u=${link}&t=${title}`
  const twitter = `https://twitter.com/intent/tweet?url=${link}&text=${title}&via=foudelom`
  const linkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${title}`
  const mailto = `mailto:?subject=${title}&body=${link}`
  const whatsapp = `whatsapp://send?text=${link}`

  return (
    <div className="post-share-btn-group">
      <a
        target="_blank"
        title="Facebook"
        href={facebook}
        rel="nofollow"
        onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=700');return false;"
      >
        <i className="fa fa-facebook" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Twitter"
        href={twitter}
        rel="nofollow"
        onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=700');return false;"
      >
        <i className="fa fa-twitter" aria-hidden="true"></i>
      </a>
      {/* <a target="_blank" href="whatsapp://send" data-text={link} data-href={link} rel="nofollow">
        <i className="fa fa-whatsapp" aria-hidden="true" />
      </a> */}
      <a
        target="_blank"
        title="Whatsapp"
        href={whatsapp}
        rel="nofollow"
      >
        <i className="fa fa-whatsapp" aria-hidden="true" />
      </a>
      {/* <a
        target="_blank"
        title="Linkedin"
        href={linkedin}
        rel="nofollow"
        onclick="javascript:window.open(this.href, '','menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=450,width=650');return false;"
      >
        <i className="fa fa-linkedin" aria-hidden="true"></i>
      </a> */}
      <a
        target="_blank"
        title="Envoyer par email"
        href={mailto}
        rel="nofollow"
      >
        <i className="fa fa-envelope" aria-hidden="true"></i>
      </a>
    </div>
  )
}

export default SocialIcons
