import React from 'react'

const Editos = props => {
  return (
    <div className="donnot-miss-widget">
      <div className="widget-title">
        <h5>Derniers Editos</h5>
      </div>
      {props.children}
    </div>
  )
}

export default Editos
