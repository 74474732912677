import React from 'react'

const Sidebar = props => {
  return (
    <div className="col-12 col-lg-3 col-md-6">
      <div className="sidebar-area">{props.children}</div>
    </div>
  )
}

export default Sidebar
