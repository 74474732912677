import React from 'react'
import { Link } from 'gatsby'

import { getPostPath } from '../../utils/helpers'
import { getFrenchDate } from '../../utils/dateHelpers'

const Edito = post => {
  const { frontmatter } = post
  const url = getPostPath(post)
  const { title, date, thumbnail } = frontmatter
  const publishDate = getFrenchDate(date, 'll')
  const isThumbnail = typeof thumbnail !== 'undefined'
  return (
    <div className="single-dont-miss-post d-flex mb-30">
      {isThumbnail && (
        <div className="dont-miss-post-thumb">
          <img src="img/blog-img/dm-1.jpg" alt="" />
        </div>
      )}
      <div className="dont-miss-post-content">
        <Link to={url} className="font-pt">{title}</Link>
        <span>{publishDate}</span>
      </div>
    </div>
  )
}

export default Edito
