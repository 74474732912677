import React from 'react'
import { Link } from 'gatsby'

import SocialIcons from '../Common/SocialIcons'

import { getPostPath } from '../../utils/helpers'
import { getFrenchDate } from '../../utils/dateHelpers'

const WelcomePost = post => {
  const { excerpt, frontmatter } = post
  const url = getPostPath(post)
  const { title, date, thumbnail } = frontmatter
  const publishDate = getFrenchDate(date, 'll')
  const isThumbnail = typeof thumbnail !== 'undefined'

  return (
    <div className="gazette-welcome-post">
      <h2 className="font-pt"><Link to={url} className="font-pt">{title}</Link></h2>
      <div className="d-sm-flex align-items-center justify-content-between">
        <p className="gazette-post-date">{publishDate}</p>
        <SocialIcons {...frontmatter} url={url} />
      </div>

      {isThumbnail && <div className="blog-post-thumbnail my-5">
          <img src="img/blog-img/1.jpg" alt="post-thumb"/>
        </div>
      }
      <p dangerouslySetInnerHTML={{ __html: excerpt }} />
      {/* <div className="post-continue-reading-share d-sm-flex align-items-center justify-content-between mt-30">
        <div className="post-continue-btn">
            <Link to={url} className="font-pt">Lire la suite <i className="fa fa-chevron-right" aria-hidden="true"></i></Link>
        </div>
        <SocialIcons {...frontmatter} url={url} />
      </div> */}
    </div>
  )
}

export default WelcomePost
