import React from 'react'

const MainContent = props => {
  return (
    <section className="main-content-wrapper section_padding_50">
      <div className="container">
        <div className="row">{props.children}</div>
      </div>
    </section>
  )
}

export default MainContent
