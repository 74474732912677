import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import config from '../utils/siteConfig'

import SEO from '../components/SEO'
import Layout from '../components/Layout'

import MainContent from '../components/Home/MainContent'
import WelcomePost from '../components/Home/WelcomePost'
import TodaysPosts from '../components/Home/TodaysPosts'
import TodaysPost from '../components/Home/TodaysPost'
import Sidebar from '../components/Home/Sidebar'
import Editos from '../components/Home/Editos'
import Edito from '../components/Home/Edito'
import Subscribe from '../components/Home/Subscribe'
// import Video from '../components/Video'
// import VideoList from '../components/VideoList'

export default ({ data }) => {
  const posts = data.posts.edges
  const editos = data.editos.edges
  // const videos = data.allYoutubeVideo.edges

  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
        <script type="text/javascript" src="/js/wa-mediabox.min.js" />
        <link
          type="text/css"
          rel="stylesheet"
          href="/css/wa-mediabox.min.css"
        />
      </Helmet>
      <MainContent>
        <div className="col-12 col-lg-9">
          {posts.map(({ node: post }) => (
            <WelcomePost key={post.id} {...post} />
          ))}
          {/* <WelcomePost {...posts[0]} />
          <TodaysPosts>
            <TodaysPost {...posts[1]} />
            <TodaysPost {...posts[2]} />
            <TodaysPost {...posts[3]} />
          </TodaysPosts> */}
        </div>
        <Sidebar>
          <Editos>
            {editos.map(({ node: edito }) => (
              <Edito key={edito.id} {...edito} />
            ))}
          </Editos>
          {/* <Subscribe /> */}
        </Sidebar>
      </MainContent>
      {/* <VideoList>
        <div className="row">
          {videos.map(({ node: video }) => (
            <Video key={video.id} {...video} />
          ))}
        </div>
      </VideoList> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    editos: allMarkdownRemark(
      filter: { frontmatter: { cat: { eq: "edito" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            type
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { ne: null } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 350)
          html
          frontmatter {
            title
            type
            url
            date
            slug
            categories
          }
        }
      }
    }
  }
`

// allYoutubeVideo(sort: { fields: [publishedAt], order: DESC }, limit: 8) {
//   edges {
//     node {
//       id
//       link
//       title
//       videoId
//       channelId
//       channelTitle
//       publishedAt
//       thumbnails {
//         medium {
//           url
//         }
//       }
//     }
//   }
// }