const moment = require('moment')

const getFrenchDate = (date, format = 'll') => {
  moment.locale('fr-FR')
  return moment(date).format(format)
}

module.exports = {
  getFrenchDate,
}
